.ProfileSection {
  margin-top: 30px;
  margin-bottom: 30px;
}

img {
  max-width: 70%;
  display: block;
  margin: 0 auto;
}

.Github {
  width: 250px;
}

.ImgContainer {
  height: 277px;
  display: flex;
  align-items: center;
  transition: transform 0.3s;
}

.ImgContainer:hover {
  transform: scale(1.1);
}

@media (max-width: 1279px) {
  img {
   width: 300px;
  }
}

@media (max-width: 599px) {
  .ImgContainer {
    height: 220px;
  }
}