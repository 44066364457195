body {
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 14%, rgb(241, 238, 235) 55%);
  background-repeat: no-repeat;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: "Roboto", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}