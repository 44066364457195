.ModalContent {
  background-color: rgb(245, 245, 245);
  width: 80%;
  margin: 20px auto;
  outline: none;
  text-align: center;
  padding: 30px;
  border-radius: 10px;
}

.ModalContent h1 {
  margin-top: 0px;
}

.ModalContent p {
  font-size: 18px;
}

.ModalContent a {
  color: rgb(43, 43, 255);
  text-decoration: none;
  font-size: 18px;
}

.ModalContent img {
  width: 90%;
}