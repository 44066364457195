.Footer {
  
}

.Icon {
  transition: all 0.3s;
}

.Icon:hover {
  transform: scale(1.5);
}
